import React, { Component } from 'react';
import logo from '../resources/images/page-content/Aviero-Insignia.svg';
import axios from 'axios';
import auth from '../utils/auth';
import Loading from './Loading';

const states = require('../utils/states.json');

class Signup extends Component {
    
    constructor(props)
    {
        super();
        this.state = {
            username : '',
            email : '',
            password : '',
            confirmPass : '',
            loadingState : states.ready
        };
    }
    
    render() { 

        return ( 
            <div className='container'>
                <div id="signup-module" className="main-module center">
                    <img className="sub-logo margin-small" src={logo} alt="" />
                    <h1>Signup</h1>

                    <form onSubmit={this.submitHandler}>
                        <input 
                            className="width-large unfulfilled"
                            type="text"
                            name="username"
                            onChange={this.changeHandler}
                            placeholder="Username"
                            value={this.state.username}
                        />
                        <br />
                        <br />
                        <input 
                            className="width-large unfulfilled"
                            type="password"
                            name="password"
                            onChange={this.changeHandler}
                            placeholder="Password"
                            value={this.state.password}
                        />
                        <br />
                        <br />
                        <input 
                            className="width-large unfulfilled"
                            type="password"
                            name="confirmPass"
                            onChange={this.changeHandler}
                            placeholder="Confirm Password"
                            value={this.state.confirmPass}
                        />
                        <br />
                        <br />
                        <input 
                            className="width-large"
                            type="text"
                            name="email"
                            onChange={this.changeHandler}
                            placeholder="Email (Optional)"
                            value={this.state.email}
                        />
                        <br />
                        <br />
                        <input type="submit" className="width-large" value="Sign Up" />
                    </form>
                </div> 
                {this.renderLoading()}
            </div>
        );
    }


    renderLoading = () => {
        //return <Loading />;
        if (this.state.loadingState === states.waitOnServer)
        {
            return <Loading />;
        }
    }


    // When the state of one of our input fields is changed, update the component state
    // So we maintain a single source of truth
    changeHandler = (event) => {
        this.setState( {[event.target.name] : event.target.value} );
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState( {loadingState : states.waitOnServer} );
        axios.post('/api/signup', {username : this.state.username, 
            email : this.state.email, password : this.state.password,
            confirmPass : this.state.confirmPass}).then(response => {
            if (response.status === 200)
            {
                console.log(`Signup for user "${this.state.username}" successful`);
                this.setState( {loadingState : states.success} );
                auth.updateSessionState().then(() => {
                    this.props.history.push('/Dashboard');
                });
            }
            else 
            {
                alert("Signup Failed");
                this.setState( {loadingState : states.finishedWithError} );
            }
            console.log("Signup Response Status:", response.status);
        }).catch(error => {
            console.log(error);
            alert("Signup Failed");
            this.setState( {loadingState : states.failure} );
        });
    }


    componentDidMount() {
        document.title = 'Aviero - Signup';
    }

}
 
export default Signup;