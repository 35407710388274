import React, { Component } from 'react';
class Profile extends Component {
    state = {  }
    
    render() { 
        return ( <div id="profile-page">
            <p>Welcome</p>
        </div> );
    }
}
 
export default Profile;