import { Component } from "react";

class EntityPage extends Component
{
    constructor(props)
    {
        super(props);
        this.state = { };
    }

    render()
    {
        return (
        <div>
            <p>Entity Page</p>
        </div>);
    }
}

export default EntityPage;