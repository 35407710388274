import React, { Component } from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Signup from './components/Signup';
import Loading from './components/Loading';
import auth from "./utils/auth";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import NotFound from './components/NotFound';
import Profile from './components/Profile';
import EntityPage from './components/EntityPage';

const states = require("./utils/states.json");

function App() {

  return (
    <MainPanel />
  );
}


class MainPanel extends Component {
  constructor(props) 
  {
    super();
    this.state = {
      loading : true
    };
  }


  render() {

    return (
      <div className="main-panel blue-border blue-full-glow">
        <div className='container'>
          <BrowserRouter>
            <Switch>
              <ProtectedRoute path='/Dashboard' exact component = {Dashboard} />
              <ProtectedRoute path='/User/*' exact component = {Profile} />
              <ProtectedRoute path='/Entity/*' exact component = {EntityPage} />
              <Route path='/Signup' exact component={Signup} />
              <Route path='/Loading' exact render={ 
                (props) => <div className= "container"><Loading /></div>} />
              <Route path='/' exact render={ (props) => this.renderHomePage(props)} />
              <Route path="*" component={NotFound} />
            </Switch>
          </BrowserRouter>
        </div>
        
      </div>
    );
  }


  renderHomePage = (props) => {
    if (this.state.loading)
    {
      return <Loading {...props} />;
    }
    else {
      if (auth.weakGetSessionState() === states.success)
      {
        //TODO: FIX THIS. Dedirects all other pages to this.
        return <Redirect to='/Dashboard' />;
      }
      else
      {
        return <Login {...props} />;
      }
    }
  }

  updateDimensions = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    document.documentElement.style.setProperty('--vw', `${window.innerWidth * 0.01}px`);
    document.documentElement.style.setProperty('--vmin', `${Math.min(
      window.innerWidth, window.innerHeight) * 0.01}px`);
    document.documentElement.style.setProperty('--vmax', `${Math.max(
      window.innerWidth, window.innerHeight) * 0.01}px`);
  }


  //When we visit the page, check to see if we are logged in
  componentDidMount() {
    this.updateDimensions();

    window.addEventListener("resize", () => {
      this.updateDimensions();
    });

    auth.updateSessionState().then( () => {
      this.setState( {loading : false} );
    });
  }

}

export default App;
