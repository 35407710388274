import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import auth from '../utils/auth';

class Navbar extends Component {
    constructor(props)
    {
        super();
        this.state = {  };
    }

    
    render() { 
        return ( 
        <div id='navbar' className='center'>
            <table className='navbar-table blue-outer-glow'>
                <tbody>
                    <tr>
                        <td className={'blue-border navbar-td'}><Link to={"/"}><button className={'navbar-button blue-inner-glow'} type="button">Home</button></Link></td>
                        <td className={'blue-border navbar-td'}><Link to={"/"}><button className={'navbar-button blue-inner-glow'} type="button">Entities</button></Link></td>
                        <td className={'blue-border navbar-td'}><Link to={"/"}><button className={'navbar-button blue-inner-glow'} type="button">Universes</button></Link></td>
                        <td className={'blue-border navbar-td'}><Link to={"/User/" + this.props.currentUser}><button className={'navbar-button blue-inner-glow'} type="button">Profile</button></Link></td>
                        <td className={'blue-border navbar-td'}><Link to={"/"}><button className={'navbar-button blue-inner-glow'} type="button">Settings</button></Link></td>
                        <td className={'blue-border navbar-td'}><button onClick={ this.handleLogout } className={'navbar-button blue-inner-glow'} type="button">Logout</button></td>
                    </tr>
                </tbody>
            </table>
        </div> );
    }

    

    handleLogout = () => {
        this.props.setMainComponentLoading(true);
        auth.logout( () => {this.props.history.push("/")} );
    }
}
 
export default Navbar;