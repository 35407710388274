import React, { Component } from 'react';
import hp_img from '../resources/images/stat-icons/Health.png';
import speed_img from '../resources/images/stat-icons/Speed.png'
import physical_damage_img from '../resources/images/stat-icons/Physical_Damage.png';
import physical_resist_img from '../resources/images/stat-icons/Physical_Resistance.png';
import special_damage_img from '../resources/images/stat-icons/Special_Attack.png'
import special_resist_img from '../resources/images/stat-icons/Special_Resist.png'
import magic_damage_img from '../resources/images/stat-icons/Magical_Damage.png';
import magic_resist_img from '../resources/images/stat-icons/Magical_Resistance.png';
import { Link } from 'react-router-dom';

// TODO: Make mini RPG/League style stat chart graphic

class EntityMiniCard extends Component {
    constructor(props)
    {
        super(props);
        this.state = {  };
    }

    render() { 
        return ( 
            <div className='blue-border blue-full-glow mini-card container'>
                <div className='container'>
                    <Link to={'/Entity'} className='mini-portrait'>
                        <img src='https://clipground.com/images/the-unknown-clipart-2.jpg' 
                            className='mini-portrait' alt="Unknown appearance"></img>
                    </Link>
                    
                    <div className='mini-grid'>
                        <h2 className='mini-title'>
                            <Link to = {'/Entity'} className='linkClean'>
                                {this.props.entity.first_name} {this.props.entity.middle_name} {this.props.entity.last_name}
                            </Link>
                            &nbsp;{this.props.entity.tier}
                        </h2>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.hp}</p>
                            <img className='icon' src={hp_img} alt="Health Icon" title="Health"/>
                        </div>

                        <div style={{display : 'flex'}}>
                        <p>{this.props.entity.physical_attack}</p>
                            <img className='icon' src={physical_damage_img} alt="Physical Damage Icon" title="Physical Damage" />
                        </div>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.special_attack}</p>
                            <img className='icon' src={special_damage_img} alt="Special Damage Icon" title="Special Damage" />
                        </div>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.magic_attack}</p>
                            <img className='icon' src={magic_damage_img} alt="Magical Damage Icon" title="Magical Damage" />
                        </div>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.speed}</p>
                            <img className='icon' src={speed_img} alt="Speed Icon" title="Speed" />
                        </div>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.physical_resist}</p>
                            <img className='icon' src={physical_resist_img} alt="Physical Resistance Icon" title="Physical Resistance" />
                        </div>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.special_resist}</p>
                            <img className='icon' src={special_resist_img} alt="Special Resistance Icon" title="Special Resistance" />
                        </div>

                        <div style={{display : 'flex'}}>
                            <p>{this.props.entity.magic_resist}</p>
                            <img className='icon' src={magic_resist_img} alt="Magical Resistance Icon" title="Magical Resistance" />
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}
 
export default EntityMiniCard;