import React, { Component, Fragment } from 'react';
import logo from '../resources/images/page-content/Aviero-Insignia.svg';
import auth from '../utils/auth.js'
import { Link } from 'react-router-dom';
import Loading from './Loading';

const states = require('../utils/states.json');

class Login extends Component {


    constructor(props)
    {
        super();
        this.state = {
            username : '',
            password : '',
            loadingStatus : states.ready
        };
    }
    
    render() { 
        const {username, password} = this.state;
        
        return ( 
            <Fragment>
                <div id='login' className='center main-module'>
                    <img className="main-logo margin-small" src={logo} alt=""/>
                    <h1>Aviero Entity Tracker</h1>
                    <br />
                    <form onSubmit={this.submitHandler} >
                        <input 
                            className="width-large blue-border-small blue-full-glow-small" 
                            type="text" 
                            name="username" 
                            onChange={this.changeHandler} 
                            placeholder="Username" 
                            value={username}
                        />
                        <br /><br />
                        <input 
                            className="width-large blue-border-small blue-full-glow-small" 
                            type="password" 
                            name="password" 
                            onChange={this.changeHandler}
                            placeholder="Password" 
                            value={password}
                        />
                        <br /><br />
                        <input type="submit" className="width-large blue-border-small blue-full-glow-small" value="Login" />
                    </form>
                    <br />
                    <Link to="/Signup"><button className="width-large blue-border-small button blue-full-glow-small">Sign Up</button></Link>
                </div>
                {this.renderLoading()}
            </Fragment>
         );
    }


    // When the state of one of our input fields is changed, update the component state
    // So we maintain a single source of truth
    changeHandler = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    renderLoading = () => {
        if (this.state.loadingStatus === states.waitOnServer)
        {
            return <Loading />;
        }
    }


    submitHandler = (event) => {
        event.preventDefault();

        this.setState({loadingStatus : states.waitOnServer});

        auth.login(this.state.username, this.state.password, () => {
            this.props.history.push('/Dashboard')}).then(state => {
            this.setState( { loadingStatus : state } );
            //this.props.setAuth(state);
        }).catch (error => {
            this.setState( { loadingStatus : states.finishedWithError } );
            //this.props.setAuth(states.finishedWithError);
        });


        // axios.post('/api/login', 
        //     {username : this.state.username, password : this.state.password}).then(response => {   
             
        //     let setState = states.ready;
        //     console.log("Login Response Status:", response.status);

        //     if (response.status === 200) {
        //         setState = states.success;
        //     }
        //     else {
        //         setState = states.finishedWithError
        //     }

        //     this.setState( { loadingStatus : setState } );
        //     this.props.setAuth(setState);
        // }).catch(error => {
        //     console.log(error);
        //     this.setState({loadingStatus : states.finishedWithError});
        // });
    }


    componentDidMount() {
        document.title = 'Aviero - Login';
    }

}
 
export default Login;