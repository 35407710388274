import axios from 'axios';
const states = require("./states.json");

class Auth {

    constructor() {
        this.authenticated = states.ready;

        //TODO - Make Auth track current username
        this.currentUser = null;

        // this.isSessionActive().then((result) => {
        //     this.authenticated = result;
        // });
        // console.log("Constructor for Auth");
    }

    weakGetSessionState = () => {
        return this.authenticated;
    }


    // A strong check to see if we are logged in. Checks with server and sets the state to reflect that
    isSessionActive = () => {
        return new Promise((resolve) => {
            axios.post("/api/sessionActive").then(response => {
                console.log("Logged in:", response.status === 200);

                if (response.status === 200) {
                    resolve(states.success);
                }
                else {
                    resolve(states.finishedWithError);
                }

                // this.setState( {loggedIn : response.status === 200 ? states.success : states.failure} );
            }).catch(error => {
                //console.log(error);
                resolve(states.finishedWithError);
                // this.setState( {loggedIn : states.failure} );
            });
        });
    }

    updateSessionState = () => {
        return new Promise (resolve => {
            this.isSessionActive().then((result) => {
                this.authenticated = result;
            }).finally( () => {resolve()});
        }); 


    }


    login = (user, pass, callback) => {
        return new Promise ((resolve, reject) => {
            let endState = states.ready;

            axios.post('/api/login', 
            {username : user, password : pass}).then(response => {   
             
            console.log("Login Response Status:", response.status);
            console.log(response.data);

            if (response.status === 200) {
                endState = states.success;
            }
            else {
                endState = states.finishedWithError;
            }
                resolve(endState);
            }).catch(error => {
                console.log(error);
                endState = states.finishedWithError;
                reject(endState);
            }).finally( () => {
                this.authenticated = endState;
                callback();
            } );
        });
    }


    logout = (callback) => {
        return new Promise( (resolve, reject) => {
            let endState = states.success;
            axios.post('/api/logout').then(response => {
                if (response.status === 200)
                {
                    endState = states.ready;
                }
                else {
                    endState = states.finishedWithError;
                }
                resolve(endState);
            }).catch(error => {
                endState = states.finishedWithError;
                reject(endState);
            }).finally( () => {
                this.authenticated = endState;
                callback();
            } );
        });
    }

}

const instance = new Auth();

export default instance;