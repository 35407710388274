import React, { Component, Fragment } from 'react';
import axios from 'axios';
import auth from '../utils/auth';
import logo from '../resources/images/page-content/Aviero-Insignia.svg';
import Navbar from './Navbar';
import Loading from './Loading';
import EntityMiniCard from './EntityMiniCard';


class Dashboard extends Component {
    constructor(props) {
        super();

        this.state = { 
            username : null,
            loading : true,
            favoriteEntities : [],
            entityOffset : 0
        }
    }


    render() { 

        return (
            <Fragment>
                <div id='dashboard' className='main-module center'>
                    <img className="sub-logo margin-small" src={logo} alt="" />
                    <Navbar currentUser={this.state.username} {...this.props} setMainComponentLoading = {this.setloading}/>
                    <h2>Favorites</h2>

                    {this.state.favoriteEntities.map(currentEntity => (
                        <EntityMiniCard {...this.props} key={currentEntity.entity_id} entity = {currentEntity} />
                    ))}

                </div>
                {this.renderLoading()}
            </Fragment>
        );
    }



    renderLoading = () => {
        if (this.state.loading === true)
        {
            return <Loading />;
        }
    }

    setloading = (isLoading) => {
        this.setState( {loading : isLoading} );
    }

    componentDidMount() {
        document.title = 'Aviero - Dashboard';

        axios.post('/api/getUsername').then( result => {
            if (result.status === 200) {
                this.setState( {username : result.data.username, loading : false} );
                document.title = this.state.username + ' - Dashboard';
            }
            else {
                console.log("Bad response from server getting username");
                auth.updateSessionState();
            }
        }).catch( error => {
            console.log(error);
            auth.updateSessionState();
        });

        axios.post('/api/loadEntities', {offset : 0}).then(entities => {
            this.setState( {favoriteEntities : this.state.favoriteEntities.concat(entities.data) } );
            console.log("Entities\n", this.state.favoriteEntities);
        }).catch(error => {
            console.log("Failed to load entities:", error);
            alert("Failed to load entities");
        });
    }
}
 
export default Dashboard;