import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from "./auth";

const states = require ('./states.json');

export const ProtectedRoute = ( { component: Component, ...rest } ) => {

    // Get the state. If we don't know, figure it out first.
    let sessionState = auth.weakGetSessionState();

    if (sessionState === states.ready)
    {
        
        console.log("Protected Routes: Updating Session State...");
        auth.updateSessionState().then( updatedState => {
            sessionState = updatedState;
            console.log("Protected Routes: Updated Session State");
        });

    }

    // Get the updated session state if we don't have it before here.

    return (
        <Route {...rest} render={ (props) =>
            {
                if (auth.weakGetSessionState() === states.ready )
                {
                    auth.updateSessionState();
                }

                else if (auth.weakGetSessionState() === states.success )
                {
                    return <Component {...props} />;
                }
                else {
                    return <Redirect to={
                        {
                            pathname : "/",
                            state : {from : props.location}
                        }
                    } />;
                }
                
            }
        } />
    );
}